<template>
  <vx-card :title="title">
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>Version</vs-th>
            <vs-th>Old Data</vs-th>
            <vs-th>New Data</vs-th>
            <vs-th>Created On</vs-th>
            <vs-th>Created By</vs-th>
            <vs-th>Updated By</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td style="vertical-align: top;">{{ tr.version }}</vs-td>
              <vs-td>
                <p class="my-3"> Code : {{ stringToJson(tr.old_value).Code  }} </p>
                <p class="my-3"> Valid From : {{ stringToJson(tr.old_value).ValidFrom | formatDate2  }} </p>
                <p class="my-3"> Valid To : {{ stringToJson(tr.old_value).ValidTo | formatDate2}} </p>
                <p class="my-3"> Name : {{ stringToJson(tr.old_value).Name }} </p>
                <p class="my-3"> Slug : {{ stringToJson(tr.old_value).Slug }} </p>
                <p class="my-3"> Description: </p>
                <p class="p-3" v-html="stringToJson(tr.old_value).Description" style="background-color: #f5f5f5; min-width: 140px;min-height: 50px;"></p>
                <p>Image: <img :src="stringToJson(tr.old_value).ImageURL" alt="" srcset="" width="50%"></p>
                <!-- <p class="my-3"><vs-icon
                    class="vs-btn-primary"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    :icon="
                      copied === null || copied !== tr.old_value
                        ? 'icon-copy'
                        : 'icon-check'
                    "
                    @click.stop="copyToClipboard(tr.old_value)"
                  >
                    {{ copied == tr.old_value ?"Copied":"Copy json to Clipboard" }}
                  </vs-icon></p> -->
              </vs-td>
              <vs-td>
                <p class="my-3"> Code : {{ stringToJson(tr.new_value).Code  }} </p>
                <p class="my-3"> Valid From : {{ stringToJson(tr.new_value).ValidFrom | formatDate2  }} </p>
                <p class="my-3"> Valid To : {{ stringToJson(tr.new_value).ValidTo | formatDate2}} </p>
                <p class="my-3"> Name : {{ stringToJson(tr.new_value).Name }} </p>
                <p class="my-3"> Slug : {{ stringToJson(tr.new_value).Slug }} </p>
                <p class="my-3"> Description: </p>
                <p class="p-3" v-html="stringToJson(tr.new_value).Description" style="background-color: #f5f5f5; min-width: 100px;min-height: 50px;"></p>
                <p>Image: <img :src="stringToJson(tr.new_value).ImageURL" alt="" srcset="" width="50%"></p>
                <!-- <p class="my-3"><vs-icon
                    class="vs-btn-primary"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    :icon="
                      copied === null || copied !== tr.new_value
                        ? 'icon-copy'
                        : 'icon-check'
                    "
                    @click.stop="copyToClipboard(tr.new_value)"
                  >
                    {{ copied == tr.new_value ?"Copied":"Copy json to Clipboard" }}
                  </vs-icon></p> -->
              </vs-td>
              <!-- <vs-td>{{ tr.old_value | formatedData }}</vs-td> -->
              <!-- <vs-td>{{ tr.new_value | formatedData }}</vs-td> -->
              <vs-td>{{ tr.created_at | formatDate }}</vs-td>
              <vs-td>{{ tr.created_by }}</vs-td>
              <vs-td>{{ tr.updated_by }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      title: "Flyer History",
      baseUrl: "/api/sfa/v1/flyer-histories",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      copied:null,
      deleteId: null,
      id: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    stringToJson(data) {
      if (data!="null") {
        // string to json
        let json = JSON.parse(data);
        return json;
      }
      return {};
    },
    async copyToClipboard(toCopy) {
      this.copied = toCopy
      try {
        await navigator.clipboard.writeText(this.formatedData(toCopy));
        this.$vs.notify({
          title: "Success",
          text: "Copied to clipboard",
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: "Failed to copy to clipboard",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    formatedData(data) {
      if (data) {
        // string to json
        let json = JSON.parse(data);
        // json to string with bracket
        let str = JSON.stringify(json, null, 2);

        return str;
      }
    },
    handleEdit(id) {
      this.id = id;
      this.detail = true;
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(value).add(-7, 'hour').format("YYYY-MM-DD HH:mm:ss");
      }
    },
    formatDate2(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DD");
      }
    },
    formatedData(data) {
      if (data) {
        // string to json
        let json = JSON.parse(data);
        // json to string with bracket
        let str = JSON.stringify(json, null, 2);

        return str;
      }
    },
  },
  watch: {},
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
